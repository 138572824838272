.closed-mark {
  border-left: 5px solid #8e8787;
  border-radius: 20px;
}

.closed-mark-top {
  border-left: 5px solid white;
  border-radius: 0px 0px 20px 20px;
}

.close-mark-bottom {
  border-left: 5px solid white;
  border-radius: 20px 20px 0px 0px;
  margin-top: 5px;
}

.plus-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
}
