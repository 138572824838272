.close-order-card {
  background: #3f3f3f !important;
  color: #959595 !important;
  border-radius: 5px !important;
  box-shadow: 0px 3px 12px rgba(84, 79, 64, 0.1) !important;
  margin: 0px !important;
  margin-bottom: 10px !important;
}

.close-card-date {
  background: white;
  width: fit-content;
  padding: 6px 10px;
  border-radius: 2px;
}

.locale-products-label {
  text-transform: uppercase;
  margin-bottom: 8px !important;
}

.close-order-card-data{
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .close-order-card-data {
      display: block;
  }
}