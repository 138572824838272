.order-history-card {
  cursor: default;
  padding: 6px 0;
}

.order-history-mark {
  height: 40px;
  width: 4px;
  border-radius: 0px 10px 10px 0px;
}

.history-edit-icon {
  width: 24px;
  margin-left: 5px;
  height: 23px;
  cursor: pointer;
}

.history-delete-icon {
  width: 25px;
  margin-left: 5px;
  height: 23px;
  cursor: pointer;
}

.order-history-table-th {
  display: flex;
  align-items: end;
  font-weight: bold !important;
  font-size: 14px !important;
}

.history-table-container {
  display: flex;
  padding-bottom: 3px;
  line-height: 20px;
}

.history-data-container {
  margin-top: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.close-order-title {
  margin-bottom: 10px;
  margin-top: 22px;
  padding-top: 12px;
  border-top: 1px solid #c5c1c1;
}
