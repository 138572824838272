.brand-name-userwelcome {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bottom-banner-image{
  position: absolute;
  bottom: 0;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 5px;
}