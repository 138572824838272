.quantity-handle-button {
  width: 22px;
  height: 25px;
  color: white;
  cursor: pointer;
}

.quantity-handle-button:active{
background:#eb5924;
}

.selected-quantity{
    min-width: 45px;
    height: 25px;
}

.minus-button {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.plus-button {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.open-order-date-picker{
  .card-date-picker {
    width: 80px;
    border: none;
    text-align: center;
    cursor: pointer;
  }
  .react-datepicker__input-container input {
    border: none;
    box-shadow: none;
    outline: none;
  }
  
  .react-datepicker__input-container input:focus {
    border: none; 
    box-shadow: none;
  }
}

.react-datepicker__triangle {
  display: none;
}

.card-order-id-box{
  display: flex;
  justify-content: space-between;
}

.card-child{
  display: flex;
  align-items: center;
  justify-content: space-between
}

.card-child-1{
  display: flex;
  gap: 15px;
}

.card-child-2{
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .card-order-id-box {
      display: block;
  }
  .card-child{
    display: block
  }
  .card-child-1{
    justify-content: space-between;
    align-items: center;
  }
  .card-child-2{
    justify-content: space-between;
  }
}