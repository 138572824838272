.client-select-acco {
  background-color: white;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  box-shadow:  0px 3px 12px rgba(84, 79, 64, 0.1);
}

.rep-status-mark {
  height: 25px;
  width: 4.5px;
  border-radius: 0px 8px 8px 0px;
}

.sec-text{
  color: #959595;
}