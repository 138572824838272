.profile-detail-title {
  font-weight: 600;
  color: #151515;
  white-space: nowrap;
  font-style: italic;
  font-size: 13px !important;
}

.profile-detail-text {
  line-height: 18px;
  margin-bottom: 8px;
}

.delete-user-text {
  display: inline-block;
  font-size: 13px;
  line-height: 22px;
  color: #e71c24;
  cursor: pointer;
}

@media (min-width: 1536px) {
  .profile-detail-title {
    font-size: 15px !important;
  }

  .profile-detail-text {
    font-size: 16px !important;
  }
}