.switch-outerBox{
  height: 13px;
  width: 40px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  padding: 2px;
  transition: background-color 0.5s ease;

}

.switch-innerBox{
  height: 13px;
  width: 13px;
  background: white;
  border-radius: 100%;
}