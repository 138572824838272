.side-buttons {
  width: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
  left: 1px;
  background-color: #3a3b3c;
}

.volume-buttom {
  height: 70px;
  top: 40px;
}
.power-button {
  height: 40px;
  top: 60px;
}

.phone-container {
  height: 90%;
  width: 320px;
  border: 10px solid #3a3b3c;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.upper-phone-space {
  background-color: #3a3b3c;
  height: 5px;
  width: 100%;
}

.lower-phone-space {
  background: #3a3b3c;
  height: 15px;
  width: 100%;
}

.waba-flow-iframe {
  flex: 1;
  width: 100%;
  border: none;
}
