.user-header-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  align-items: center;
  border-bottom: 2px solid #fff;
  flex-wrap: wrap;
  gap: 5px;
}

.user-data-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-menu-container {
  background: white;
  margin: 0px 20px 0px 20px;
  border: 1px solid #959595;
  border-radius: 5px;
  padding: 8px 0px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.user-menu-options {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 16px;
  font-weight: 400;
}

.user-menu-options:hover {
  font-weight: 800;
}

.log-out-dialog-box-contents {
  padding: 5px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.yes-no-buttons {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  border-top: 1px solid #e5e5e5;
}

.header-icon {
  height: 35px;
  width: 35px;
}