.accountL-list-container {
  max-height: 200px;
  overflow: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.account-list-item {
  cursor: pointer;
  border-radius: 5px;
  padding: 7px 25px;
}

.account-list-item:hover {
  background-color: #888888;
  color: white;
}

.account-list-item:active {
  background-color: #757171;
  color: white;
}

.order-card-container  {
  flex: 1
}

@media (max-width: 768px) {
  .order-card-container  {
      flex: none;
  }
}