.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}
.fixed {
  position: fixed;
}

.b0 {
  bottom: 0px;
}

.b5 {
  bottom: 5px;
}

.b10 {
  bottom: 10px;
}

.b15 {
  bottom: 15px;
}

.b20 {
  bottom: 20px;
}

.b25 {
  bottom: 25px;
}

.b30 {
  bottom: 30px;
}

.t0 {
  top: 0px;
}

.t5 {
  top: 5px;
}

.t10 {
  top: 10px;
}

.t15 {
  top: 15px;
}

.t20 {
  top: 20px;
}

.t25 {
  top: 25px;
}

.t30 {
  top: 30px;
}

.r0 {
  right: 0px;
}

.r5 {
  right: 5px;
}

.r10 {
  right: 10px;
}

.r15 {
  right: 15px;
}

.r20 {
  right: 20px;
}

.r25 {
  right: 25px;
}

.r30 {
  right: 30px;
}

.l5 {
  left: 5px;
}

.l10 {
  left: 10px;
}

.l15 {
  left: 15px;
}

.l20 {
  left: 20px;
}

.l25 {
  left: 25px;
}

.l30 {
  left: 30px;
}



.m0 {
  margin: 0px;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m25 {
  margin: 25px;
}

.m30 {
  margin: 30px;
}

.mt0 {
  margin-top: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.ml0 {
  margin-left: 0px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.mr0 {
  margin-right: 0px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.transparent-bg {
  background: transparent;
}

.zi1 {
  z-index: 1;
}

.zi2 {
  z-index: 2;
}
.zi3 {
  z-index: 3;
}
.zi4 {
  z-index: 4;
}
.zi5 {
  z-index: 5;
}

.flex {
  display: flex;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.jc-ar {
  justify-content: space-around;
}

.jc-e {
  justify-content: space-around;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-fs {
  justify-content: flex-start;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.ai-e {
  align-items: end;
}

.f1 {
  flex: 1;
}

.f2 {
  flex: 2;
}

.f3 {
  flex: 3;
}

.fdc {
  flex-direction: column;
}

.fdr {
  flex-direction: row;
}

.font11 {
  font-size: 11px !important;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 18px !important;
}

.font20 {
  font-size: 20px !important;
}

.font22 {
  font-size: 22px !important;
}

.font24 {
  font-size: 24px !important;
}

.fw1 {
  font-weight: 100;
}
.fw2 {
  font-weight: 200;
}
.fw3 {
  font-weight: 300;
}
.fw4 {
  font-weight: 400;
}
.fw5 {
  font-weight: 500;
}
.fw6 {
  font-weight: 600;
}
.fw7 {
  font-weight: 700;
}
.fw8 {
  font-weight: 800;
}

.lh5 {
  line-height: 5px;
}

.lh10 {
  line-height: 10px;
}

.lh15 {
  line-height: 15px;
}

.lh20 {
  line-height: 20px;
}

.lh25 {
  line-height: 25px;
}

.lh30 {
  line-height: 30px;
}

.g5 {
  gap: 5px;
}

.g10 {
  gap: 10px;
}

.g15 {
  gap: 15px;
}

.g20 {
  gap: 20px;
}

.g25 {
  gap: 25px;
}

.g30 {
  gap: 30px;
}

.t-c {
  text-align: center;
}

.tar {
  text-align: right;
}

.tal {
  text-align: left;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.auto-o {
  overflow: auto;
}

.uppercase {
  text-transform: uppercase;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

.pr30 {
  padding-right: 30px;
}


.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p25 {
  padding: 25px;
}

.p30 {
  padding: 30px;
}

.pointer{
  cursor: pointer;
  outline: none;
}

.us-remove{
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.br5{
  border-radius: 5px;
}

.br10{
  border-radius: 10px;
}

.fullvh{
  height: 100vh;
}

.fullvw{
  width: 100vw;
}

.tnc-typography{
  font-size: 11px !important;
}

@media (max-width: 1024px) {
  .tnc-typography {
    font-size: 8px !important;
  }
}

@media (max-width: 768px) {
  .tnc-typography {
    font-size: 7px !important;
  }
}

@media (max-height: 550px) {
  .tnc-typography {
    font-size: 7px !important;
  }
}

@media (max-height: 350px) {
  .tnc-typography {
    font-size: 5px !important;
  }
}

.sameline{
  white-space: nowrap;
}


.borderBox{
  box-sizing: border-box;
}

.react-datepicker-popper{
  z-index: 5;
}