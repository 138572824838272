@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-Thin.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-Thin.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-Thin.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-Thin.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-ThinItalic.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-ThinItalic.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-ThinItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-Thin.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-Thin.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-Thin.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-Thin.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-ThinItalic.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-ThinItalic.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-ThinItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-Light.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-Light.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-Light.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-LightItalic.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-LightItalic.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-Regular.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-Regular.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-Regular.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-RegularItalic.woff2") format("woff"),
    url("./assets/fonts/GSKPrecision-RegularItalic.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-RegularItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-Bold.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-Bold.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-Bold.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-BoldItalic.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-BoldItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-Bold.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-Bold.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-Bold.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "GSK";
  src: url("./assets/fonts/GSKPrecision-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/GSKPrecision-BoldItalic.woff") format("woff"),
    url("./assets/fonts/GSKPrecision-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/GSKPrecision-BoldItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 600;
}

html,
body {
  margin: 0;
  background-color: #f0efed;
  font-family: "GSK", -apple-system, BlinkMacSystemFont, sans-serif !important;
  height: 100vh;
}

*{
  font-family: "GSK", -apple-system, BlinkMacSystemFont, sans-serif !important;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.text-tiny{
  font-size: 10px !important;
}

.text-small{
  font-size: 11px !important; 
}

.text-big{
  font-size: 18px !important;
}

.text-huge{
  font-size: 22px !important;
}