.main-container {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.child-container {
  display: flex;
  flex-direction: column;
}

.order-tab {
  font-size: 15px !important;
  font-weight: bold !important;
  margin: 5px;
  padding-bottom: 3px;
  cursor: pointer;
  padding-left: 15px;
}

.noOfPharmacies {
  font-size: 15px;
  position: relative;
  bottom: 3px;
  right: 10px;
  font-weight: 500;
  color: rgb(53, 50, 50);
  flex: 1;
  text-align: right;
  white-space: nowrap;
  padding-left: 10px;
}

.no-of-pharmacies-input {
  height: 21px;
  padding: 0px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: right;
}

.no-of-pharmacies-input:focus {
  outline: none;
}

.userinput-container {
  background-color: white;
  padding: 13px 15px;
  border-radius: 7px;
  box-shadow: 0px 7px 5px -6px rgba(0, 0, 0, 0.15);
}

.form-container {
  display: flex;
  gap: 12px;
}

.left-side-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.right-side-div {
  width: 157px;
  display: flex;
  flex-direction: column;
}

.sku-filter-input {
  padding-left: 9px;
  font-size: 13.2px;
}

.sku-filter-input::placeholder {
  font-style: italic;
}

.date-picker-form {
  width: 137px;
  padding-left: 6px;
  font-size: 14px;
  font-weight: 400;
  padding-top: 0.5px;
  padding-bottom: 0.5px;
}

.date-picker-form::placeholder {
  font-size: 11.5px;
  padding-left: 0px;
}
.sku-table-container {

  border-radius: 4px;
  border: 1px solid black;
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 15px;
  flex: 1;
  overflow: auto;
  table {
    border-collapse: collapse;
    width: -webkit-fill-available;
  }

  td,
  th {
    border: 1px solid black;
    padding: 2px;
  }

  th {
    text-align: left;
    padding: 1px 4px !important;
    font-size: 12.5px !important;
    font-weight: 400;
  }

  th {
    background-color: #f07a19 !important;
    color: white !important;
  }
}

.title-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 5px;
}

.order-summary {
  font-size: 23.5px;
  color: #f07a19;
  padding-bottom: 4px;
}

.wholesaler-selector {
  padding: 3px 6px;
  width: 100%;
  font-size: 13.5px;
}

.sku-clear-button {
  font-size: 12px;
  padding: 3px;
  /* height: 22px; */
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font500 {
  font-weight: 500;
}

.discount-ptw {
  width: 70px;
  border-top: 0px !important;
}

.requested-volume {
  width: 75px;
  border-right: 0px !important;
  border-top: 0px !important;
  text-align: center;
}

.sku-name {
  border-top: 0px !important;
  border-left: 0px !important;
}

.center-text {
  text-align: center;
}

button {
  display: block;
  border: 1px solid darkgray;
  background-color: rgb(240, 122, 25);
  color: rgb(255, 255, 255);
  padding: 4px 12px;
}

.react-datepicker__triangle {
  display: none;
}

.custom-calendar-icon {
  position: relative;
}

.custom-calendar-icon::after {
  content: url("./SVGIcon/calenderIcon.svg");
  position: absolute;
  right: 5px;
  top: 14px;
  transform: translateY(-50%);
  pointer-events: none;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
}

.gsk-font {
  font-family: "GSK", arial, sans-serif !important;
}

.full-width {
  width: 100%;
}

.discount-range-table {
  border-collapse: separate !important;
  border-spacing: 0px;
  td:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  td:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: black;
  z-index: 1;
}

.summary-table {
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px;
}

.summary-table-item {
  display: block;
  color: white;
  font-family: "GSK", arial, sans-serif !important;
  font-size: 12px;
}

.summary-left-side {
  text-align: right;
  padding: 5px;
  border-bottom-left-radius: 4px;
  background: #f07a19;
  border: 1px solid black;
}

.summary-right-side {
  padding: 5px;
  border: 1px solid black;
  border-left: 0px;
  background: #f07a19;
  border-bottom-right-radius: 5px;
}

.sku-name-column {
  border-left: 0px !important;
  border-bottom: none !important;
}

.offer-column {
  color: #f26633;
  width: 60px;
}

.no-left-border {
  border-left: 0px !important;
}

.orange-color {
  color: #f26633;
}

.input-column {
  border-right: 0px !important;
  border-bottom: none !important;
}

.block-display {
  display: block;
}

.pop-up-message-container {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 40%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-up-message-child {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  width: 300px;
  background: white;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}

.pop-up-message-button {
  font-family: "GSK", arial, sans-serif !important;
  font-weight: 500;
  font-size: 12px;
  min-width: 100px;
  background: #fae5d6;
  color: black;
}

.pop-up-message-button:active {
  background: #ecc5a9;
}

.pop-message-text {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-size: 14px;
}

.pop-up-button-container {
  display: flex;
  gap: 10px;
}

.error-message {
  color: red;
  font-size: 12px !important;
}

.primary-button {
  border-radius: 4px;
  border: none;
  box-shadow: 0px 7px 5px -6px rgba(0, 0, 0, 0.25);
}

.primary-button:active {
  background-color: rgb(203, 104, 23);
}

.confirm-form-button {
  border-radius: 4px;
  border: 1px solid #f26633;
  background: white;
  color: #f26633;
  font-weight: bold;
  padding: 3px 18px;
}

.confirm-form-button:active {
  background: rgb(239, 235, 235);
}

.discount-table-td {
  padding-bottom: 1px !important;
  padding-top: 1px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.custom-calendar-icon {
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    background: transparent;
  }
}

.active-tab {
  color: #f26633;
  border-bottom: 2px solid #f26633;
}

.inactive-tab {
  color: black;
  border-bottom: 2px solid grey;
}

.open-order-card {
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: default;
  display: flex;
  padding: 6px 0;
}

.new-order-button {
  color: black;
  border: 1px solid #f26633;
  font-weight: bold;
  font-size: 14.5px;
  padding: 4px 40px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 6px;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
}
.new-order-button:active {
  background: rgb(245, 243, 243) !important;
}

.icon-click-effect:active {
  height: 15px !important;
}

.orange-bold {
  color: #f26633;
  font-weight: bold;
}

.flex1 {
  flex: 1;
}

.highlight-date {
  background-color: #f07a19 !important;
  color: white !important;
  border-radius: 5px;
  font-weight: normal;
}

.order-history-mark {
  height: 40px;
  width: 4px;
  border-radius: 0px 10px 10px 0px;
}

.order-history-container {
  overflow: auto;
  padding: 0px 25px 8px 25px;
  padding-bottom: 25px;
}

.order-history-container::-webkit-scrollbar {
  width: 10px;
}

.order-history-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.order-history-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

button{
  cursor: pointer;
}