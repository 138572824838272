.progress-card-container{
    background: white;
    padding: 6px 5px 6px 12px;
    box-shadow: 0px 3px 12px rgba(84, 79, 64, 0.1);
    border: 1px solid #959595;
    border-radius: 5px;
}

.cart-icon{
    font-size: 24px !important;
    color: black;
}

.cart-quntity{
    color: white;
    padding: 3px;
    height: 15px;
    width: 15px;
    border-radius: 20px;
}

.plus-icon-brand-selection {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .brand-name-brand-selection{
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
.brand-container {
    flex: 1
}

@media (max-width: 768px) {
    .brand-container {
        flex: none;
    }
}